@import '../../../../scss/theme-bootstrap';

// Main Navigation - sections
.header-nav-section {
  &__section {
    padding-bottom: 12px;
    position: relative;
    font-size: 12px;
    width: 100%;
    @include breakpoint($landscape-up) {
      padding-bottom: 60px;
      padding: 0 15px;
      height: 100%;
      width: auto;
      float: $ldirection;
      display: flex;
      align-items: center;
    }
    .header-nav-section__trigger:checked + & {
      background-color: $color-white;
      display: block;
      @include breakpoint($landscape-up) {
        background-color: transparent;
        overflow-y: inherit;
        display: flex;
        top: auto;
        z-index: auto;
        margin-bottom: auto;
      }
      .product-full--genaissance &,
      .mpp-container--dark-background & {
        background-color: $color-neutral-black;
      }
    }
    .site-header-formatter__trigger-reset:not(:checked)
      ~ div
      .header-nav-section__trigger:not(:checked)
      + & {
      @include breakpoint($landscape-up) {
        display: flex;
      }
    }
    &:first-of-type {
      > label {
        @include breakpoint($med-small-down) {
          display: none;
        }
      }
    }
    &-label {
      color: $color-black;
      cursor: pointer;
      display: flex;
      align-items: center;
      height: $gnav-mobile-link-height;
      line-height: 1.4;
      letter-spacing: 0.05em;
      padding: 0 $gnav-mobile-hpad--outer;
      text-decoration: none;
      @include breakpoint($landscape-up) {
        height: auto;
        padding: 0;
      }
      &:hover {
        color: $color-black;
        text-decoration: none;
      }
      &--text {
        width: 100%;
        display: block;
        cursor: pointer;
        line-height: $gnav-mobile-link-height;
        text-decoration: none;
        text-align: $ldirection;
        font-size: 26px;
        font-family: $font--heading;
        color: $color-light-black;
        letter-spacing: -0.03em;
        &:hover {
          color: $color-black;
          text-decoration: none;
        }
        @include breakpoint($landscape-up) {
          line-height: normal;
          font-family: $font--text;
          font-size: 14px;
          color: $color-darker-gray;
          text-transform: uppercase;
          letter-spacing: 0.05em;
          &:hover {
            color: $color-darker-gray;
            text-decoration: none;
            border-bottom: none;
          }
        }
      }
    }
    .header-nav-section__mobile-section-toggle {
      > .icon.expandable {
        display: inline-block;
      }
      > .icon--minus {
        display: none;
      }
    }
    &[aria-expanded='true'] {
      .header-nav-section__mobile-section-toggle {
        > .icon.expandable {
          display: none;
        }
        > .icon--minus {
          display: inline-block;
        }
      }
      .header-nav-section__section-content {
        display: block;
        height: auto;
      }
      .gnav-section-formatter__content-wrapper {
        height: auto;
      }
    }
  }
  &__section-toggle {
    display: none;
    @include breakpoint($landscape-up) {
      display: block;
      position: absolute;
      #{$rdirection}: 40px;
      top: 20px;
      z-index: $gnav-fixed-z-index;
    }
    svg {
      height: 14px;
      width: 14px;
      .product-full--genaissance &,
      .mpp-container--dark-background & {
        fill: $color-white;
        @include breakpoint($landscape-up) {
          &:focus,
          &:hover {
            fill: $color-neutral-gray;
          }
        }
      }
    }
  }
  &__section-content {
    background-color: $color-white;
    display: none;
    height: 0;
    width: 100%;
    @include breakpoint($landscape-up) {
      @include transition(height 0.25s ease, opacity 0.25s ease, visibility 0.25s ease);
      #{$ldirection}: 0;
      opacity: 0;
      visibility: hidden;
      z-index: $gnav-fixed-z-index;
      top: $gnav-height;
      overflow: hidden;
      position: fixed;
      display: block;
    }
    .product-full--genaissance &,
    .mpp-container--dark-background & {
      background-color: $color-neutral-black;
    }
    // expanded state
    .header-nav-section__trigger:checked + .header-nav-section__section & {
      @include breakpoint($landscape-up) {
        display: block;
        opacity: 1;
        visibility: visible;
        height: 0;
        position: fixed;
        z-index: $gnav-fixed-z-index;
        overflow: visible;
        max-width: $container-max-width;
        #{$ldirection}: 50%;
        transform: translateX(-50%);
      }
      .gnav-section-group > div:nth-child(1) {
        .gnav-section-formatter__content-wrapper {
          @include breakpoint($landscape-up) {
            opacity: 1;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100vw;
            position: relative;
            #{$ldirection}: 50%;
            #{$rdirection}: 50%;
            margin-#{$ldirection}: -50vw;
            margin-#{$rdirection}: -50vw;
            max-width: none;
          }
        }
        .gnav-section-formatter__link:hover ~ .gnav-section-formatter__content-wrapper {
          @include breakpoint($landscape-up) {
            opacity: 1;
          }
        }
      }
    }
    .site-header-formatter--sticky &,
    .gnav-offers-hidden & {
      @include breakpoint($landscape-up) {
        top: $navbar-height;
      }
    }
  }
  &__mobile-menu-icon {
    display: inline-block;
    fill: $color-black;
    opacity: 1;
    position: absolute;
    top: 0;
    #{$rdirection}: 22px;
    height: $gnav-mobile-link-height;
    width: $gnav-icon--inline;
    @include breakpoint($landscape-up) {
      background: none;
      display: none;
    }
    &:hover,
    &:active {
      fill: $color-black;
    }
    // Arrow displays for mobile expanders only.
    .header-nav-section__trigger:checked + .header-nav-section__section & {
      opacity: 1;
    }
  }
  &__mobile-section-toggle {
    opacity: 1;
    position: absolute;
    top: 0;
    #{$rdirection}: 20px;
    @include breakpoint($landscape-up) {
      display: none;
    }
  }
  &__mobile-icon {
    display: inline-block;
    height: $gnav-icon--inline;
    width: $gnav-icon--inline;
    &.icon--minus {
      display: none;
    }
  }
}

body {
  // GNAV simplified on all checkout pages, excluding the cart page.
  &#index,
  &#samples,
  &#shipping,
  &#billing,
  &#review,
  &#confirm {
    .header-nav-section {
      display: none;
    }
  }
}

// @todo ASMBLY7-659 / hot fix, implement permanent solution once live site issue is resolved
#header_nav_section_label_1,
#header_nav_section_label_2 {
  display: none;
  @include breakpoint($landscape-up) {
    display: block;
  }
}
